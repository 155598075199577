import React, {Component} from "react";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {isAmount, isEmpty, rounded, substringDate} from "../utils/commonFunctions";
import {Calendar} from "primereact/calendar";
import {DateService} from "../../service/util/DateService";
import {AutoComplete} from "primereact/autocomplete";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {InputTextarea} from "primereact/inputtextarea";

class Report extends Component {
    serviceTypes = [
        {label: 'Service', value: 'SE'},
        {label: 'Construction', value: 'CT'},
        {label: 'PM', value: 'PM'},
        {label: 'Dispatch', value: 'DP'}
    ];
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: false,
            datesFilter: [],
            technicians: [],
            auditTime: [],
            filteredTechnicians: null,
            technicianForm: '',
            emailSelect: '',
            teams: [],
            filteredTeams: null,
            teamForm: '',
            teamSelect: '',
            weeks: [],
            filteredWeeks: null,
            weekForm: null,
            weekSelect: undefined,
            workOrder: '',
            report: [],
            daysProcessed: [],
            rawReport: [],
            dataTableSelectValue: this.emptyTimesheet,
            serviceTypeForm: '',
            errors: {},
            processSuccess: true,
            timesheetDialog: false,
            auditTimesheetDialog: false,
        }

        this.searchReport = this.searchReport.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.changeHandlerTimesheet = this.changeHandlerTimesheet.bind(this);
        this.headerTemplate = this.headerTemplate.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        this.templateTechnician = this.templateTechnician.bind(this);
        this.searchTechnicians = this.searchTechnicians.bind(this);
        this.templateTeam = this.templateTeam.bind(this);
        this.searchTeams = this.searchTeams.bind(this);
        this.templateWeek = this.templateWeek.bind(this);
        this.searchWeeks = this.searchWeeks.bind(this);
        //this.getListProcessedWeeks = this.getListProcessedWeeks.bind(this);
        this.dateTemplate = this.dateTemplate.bind(this);
        this.clearFilters = this.clearFilters.bind(this);
        this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
        this.editTimeSheet = this.editTimeSheet.bind(this);
        this.updateTimeSheet = this.updateTimeSheet.bind(this);
        this.hideDialog = this.hideDialog.bind(this);
        this.auditTimesheets = this.auditTimesheets.bind(this);
        this.worxflowServices = new WorxflowServices();
        this.dateService = new DateService();
    }
    emptyTimesheet = {
        id: 0,
        week_payroll: "",
        control_id: 0,
        work_order: "",
        customer_id: 0,
        customer: "",
        date: "",
        after_hours: "",
        per_diem: null,
        pto: null,
        work_started: "",
        work_ended: "",
        total_hours: null,
        total_hours_f: 0,
        work_st: null,
        work_ot: null,
        work_pto: null,
        work_diff: null,
        is_legacy: true,
        status: "",
        d_created: "",
        first_name: "",
        last_name: "",
        email: "",
        address: null,
        st_hours_f: 0,
        ot_hours_f: null,
        differential_hours_f: null,
        qty_hours_fe_f: null,
        difference_timesheet_f: 0,
        type: "",
        notes: ""
    };
    componentDidMount() {
        //this.getListProcessedWeeks();
        //this.loadWeeks();
        this.searchReport(new Date());
        const technicians = JSON.parse(localStorage.getItem('technicians'));
        if (technicians == null) {
            this.loadTechnicians();
        } else {
            this.setState({ technicians: technicians, chargedYet: true });
        }
        const teams = JSON.parse(localStorage.getItem('teams'));
        if (teams == null) {
            this.loadTeams();
        } else {
            this.setState({ teams: teams });
        }
    }
    getListProcessedWeeks() {
        this.worxflowServices.getListProcessedWeeks().then(resp => {
            let daysProcessed = [];
            resp.forEach(week => {
                let yyMmDd = week.started.split('-');
                let started = new Date(yyMmDd[0], yyMmDd[1]-1, yyMmDd[2]);
                yyMmDd = week.ended.split('-');
                let ended = new Date(yyMmDd[0], yyMmDd[1]-1, yyMmDd[2]);
                while(started <= ended){
                    let newDate = new Date(started.getFullYear(), started.getMonth(), started.getDate());
                    daysProcessed.push(newDate);
                    started.setDate(started.getDate() + 1);
                }
            });
            this.setState({daysProcessed: daysProcessed});
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
    }
    loadTechnicians(){
        this.worxflowServices.getTechniciansList().then(resp => {
            let technicians = [];
            const map = new Map();
            resp.data.forEach(element => {
                if(!map.has(element.email)) {
                    if(!isEmpty(element.email)){
                        map.set(element.email, true);
                        technicians.push({
                            value: element.email,
                            label: element.first_name + ' ' + element.last_name + " - " + element.email
                        });
                    }
                }
            });
            this.setState({
                technicians: technicians,
                chargedYet: true
            });
            localStorage.setItem('technicians', JSON.stringify(technicians));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }
    loadTeams(){
        this.worxflowServices.getTeamsList().then(resp => {
            let teams = [];
            const map = new Map();
            resp.forEach(element => {
                if(!map.has(element.id)) {
                    map.set(element.id, true);
                    teams.push({value: element.id, label: element.name});
                }
            });
            this.setState({teams: teams});
            localStorage.setItem('teams', JSON.stringify(teams));
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }
    loadWeeks() {
        this.worxflowServices.getListProcessedWeeks().then(resp => {
            let weeks = [];
            const map = new Map();
            resp.forEach(element => {
                if(!map.has(element.id)) {
                    map.set(element.id, true);
                    weeks.push({value: element.id, label: element.week});
                }
            });
            weeks = weeks.sort((a, b) => a - b);
            //this.setState({weeks: weeks});
            this.setState({weeks: resp});
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }
    searchReport = () => {
        const paramsValidate = {
            date_start: this.dateService.dateToStringFront3(this.state.datesFilter[0]),
            date_end: this.dateService.dateToStringFront3(this.state.datesFilter[1]),
            workOrder: this.state.workOrder
        };
        this.setState({processSuccess: true});
        const errors = this.validate(paramsValidate);
        if (Object.keys(errors).length ===  0) {
            const params = {
                date_start: paramsValidate.date_start,
                date_end: paramsValidate.date_end,
                email: (this.state.emailSelect !== '') ? this.state.emailSelect.value : null,
                team_id: (this.state.teamSelect !== '') ? this.state.teamSelect.value : null,
                type: this.state.serviceTypeForm === undefined ? '' : this.state.serviceTypeForm,
                work_order: this.state.workOrder
            };
            this.worxflowServices.searchReportTimesheet(params).then(resp => {
                if (resp.status_code === 200) {
                    const rawReport = [];
                    resp.data.forEach(element => {
                        let dataHeader = "Week:" + element.semana +
                            " Start: " + element.fecha_inicial +
                            " End: " + element.fecha_final +
                            " Total Hours: " + rounded(element.total_hours_f);
                        if (element.datos === null || element.datos.length === 0) {
                            element.header = dataHeader;
                            element.footerTotalHoursF = rounded(element.total_hours_f);
                            element.footerTotalPerDiem = element.total_per_diem;
                            element.footerTotalPto = element.total_pto;
                            element.footerTotalWorkSt = element.total_work_st;
                            element.footerTotalWorkOt = element.total_work_ot;
                            element.footerTotalWorkPto = element.total_work_pto;
                            element.footerTotalWorkDiff = element.total_work_diff;
                            element.fullName = "";
                            rawReport.push(element);
                        }
                        element.datos.forEach(e => {
                            e.header = dataHeader;
                            e.footerTotalHoursF = rounded(element.total_hours_f);
                            e.footerTotalPerDiem = element.total_per_diem;
                            e.footerTotalPto = element.total_pto;
                            e.footerTotalWorkSt = element.total_work_st;
                            e.footerTotalWorkOt = element.total_work_ot;
                            e.footerTotalWorkPto = element.total_work_pto;
                            e.footerTotalWorkDiff = element.total_work_diff;
                            e.total_hours_f = rounded(e.total_hours_f);
                            e.d_created = substringDate(e.d_created, 10);
                            e.fullName = e.first_name + " " + e.last_name;
                            rawReport.push(e);
                        });
                    });
                    this.setState({rawReport, report: resp.data});
                }
                this.setState({chargedYet: true});
            }).catch(error => {
                this.setState({chargedYet: true});
                this.toast.show({severity: 'error', summary: 'Error', detail: error.response?.data?.message});
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.setState({errors, processSuccess: false});
        }
    }
    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({
            [event.target.name]: event.target.value,
            errors
        });
        if(event.target.name === 'technicianForm'){
            this.setState({
                technicianForm: event.target.value,
                emailSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
        if(event.target.name === 'teamForm'){
            this.setState({
                teamForm: event.target.value,
                teamSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
        if(event.target.name === 'weekForm'){
            this.setState({
                weekForm: event.target.value,
                weekSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
    }
    validate = (data) => {
        let errors = {};
        if (data.date_start === null || data.date_end === null) {
            errors.datesFilter = 'Select a date range';
        }

        if (data.workOrder !== '') {
            delete errors.date_search
        }

        return errors;
    };
    headerTemplate(data) {
        return (
            <span key={data.id + '_headerLabel'} className="header_timesheet_management">
                {data.header}
            </span>
        );
    }
    footerTemplate(data, index) {
        return (
            <React.Fragment>
                <td key={data.id + '_footerLabel'} colSpan={5} style={{textAlign: 'right', width: 670, display:'block'}}>Totals</td>
                <td style={{textAlign: 'center', width: 80, display:'block'}}>{data.footerTotalPerDiem}</td>
                <td style={{textAlign: 'center', width: 80, display:'block'}}>{data.footerTotalPto}</td>
                <td style={{width: 120}}/>
                <td style={{width: 100}}/>
                <td style={{textAlign: 'center', width: 100, display:'block'}}>{data.footerTotalHoursF}</td>
                <td style={{textAlign: 'center', width: 100, display:'block'}}>{data.footerTotalWorkSt}</td>
                <td style={{textAlign: 'center', width: 100, display:'block'}}>{data.footerTotalWorkOt}</td>
                <td style={{textAlign: 'center', width: 100, display:'block'}}>{data.footerTotalWorkPto}</td>
                <td style={{textAlign: 'center', width: 200, display:'block'}}></td>
                <td style={{width: 100}}/>
                <td style={{width: 160}}/>
                <td style={{width: 100}}/>
                <td style={{width: 100}}/>
                <td style={{width: 300}}/>
                <td style={{minWidth: '4rem', width: 40}}/>
            </React.Fragment>
        );
    }
    templateTechnician(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }
    templateTeam(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }
    templateWeek(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }
    dateTemplate(date) {
        let isExists = false;
        for (let i = 0; i < this.state.daysProcessed.length; i++) {
            if(this.state.daysProcessed[i].getFullYear() === date.year &&
                this.state.daysProcessed[i].getMonth() === date.month &&
                this.state.daysProcessed[i].getDate() === date.day){
                isExists = true;
            }
        }
        if (isExists) {
            return (
                <div style={{backgroundColor: '#1dcbb3', color: '#ffffff', borderRadius: '10%', padding: '1px 0px 2px 0px'}}>{date.day}</div>
            );
        }
        else {
            return date.day;
        }
    }
    searchTechnicians(event) {
        setTimeout(() => {
            let filteredTechnicians;
            if (!event.query.trim().length) {
                filteredTechnicians = [...this.state.technicians];
            } else {
                filteredTechnicians = this.state.technicians.filter((technician) => {
                    return technician.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredTechnicians });
        }, 250);
    }
    searchTeams(event) {
        setTimeout(() => {
            let filteredTeams;
            if (!event.query.trim().length) {
                filteredTeams = [...this.state.teams];
            } else {
                filteredTeams = this.state.teams.filter((team) => {
                    return team.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredTeams });
        }, 250);
    }
    searchWeeks(event) {
        setTimeout(() => {
            let filteredWeeks;
            if (!event.query.trim().length) {
                filteredWeeks = [...this.state.weeks];
            } else {
                filteredWeeks = this.state.weeks.filter((week) => {
                    return (week.label.toString()).toLowerCase().includes((event.query.toString()).toLowerCase());
                });
            }
            this.setState({ filteredWeeks });
        }, 250);
    }

    clearFilters = () => {
        this.setState({
            datesFilter: [],
            technicianForm: '',
            teamForm: '',
            workOrder: '',
            serviceTypeForm: '',
        })
    }

    actionBodyTemplate(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" className="p-button-rounded small-button" onClick={() => this.editTimeSheet(rowData)} />
            </React.Fragment>
        );
    }

    editTimeSheet(row) {
        this.setState({
            dataTableSelectValue: { ...row },
            timesheetDialog: true
        });
    }

    updateTimeSheet(){
        this.setState({chargedYet: false})
        let rawReport = [...this.state.rawReport];
        let dataTableSelectValue = {...this.state.dataTableSelectValue};
        // call service update
        this.worxflowServices.updateTimesheetDif(dataTableSelectValue).then(resp => {
            if (resp.status_code === 200) {
                let report = rawReport.map(p => p.id === dataTableSelectValue.id ? dataTableSelectValue : p);
                this.toast.show({ severity: 'success', summary: 'Successful', detail: 'Timesheet Updated', life: 3000 });
                this.setState({
                    timesheetDialog: false,
                    rawReport: report,
                    dataTableSelectValue: this.emptyTimesheet,
                    chargedYet: true
                });
            }
        }).catch(error => {
            this.setState({chargedYet: true});
            this.toast.show({severity: 'error', summary: 'Error', detail: error.response?.data?.message});
            console.error("Error en el servicio: ", error);
        });
    }

    hideDialog() {
        this.setState({
            timesheetDialog: false,
            auditTimesheetDialog: false
        });
    }

    changeHandlerTimesheet = (e) => {
        let dataTableSelectValue = {...this.state.dataTableSelectValue};
        if (e.target.name === 'qty_hours_fe_f' && !isAmount(e.target.value)) return
        if (e.target.name === 'qty_hours_fe_f') {
            dataTableSelectValue['difference_timesheet_f'] = Number(e.target.value) - Math.abs(Number(dataTableSelectValue['total_hours_f']));
        }

        dataTableSelectValue[`${e.target.name}`] = e.target.value;
        this.setState({dataTableSelectValue});
    };

    auditTimesheets() {
        debugger
        let dataTableSelectValue = {...this.state.dataTableSelectValue};
        const id = dataTableSelectValue.id;
        if (dataTableSelectValue.id !== 0) {
            this.worxflowServices.auditTimesheet(id).then(resp => {
        if (resp.status === "success") {
            const respAudit = resp.data;
            this.setState({respAudit,
                auditTimesheetDialog: true,});
        }
    }).catch(error => {
    this.toast.show({severity: 'error', summary: 'Error', detail: error.response?.data?.message});
    console.error("Error en el servicio: ", error);
    });
        } else {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Select only one row.'
            });
        }
}




    render() {
        const auditTimesheetsDialog = (
            <Dialog visible={this.state.auditTimesheetDialog} style={{width: '11    00px'}} header="Audit Technician"
                    className="p-fluid"  onHide={this.hideDialog}>
                <div className="grid datatable-crud-demo">
                    <div className="card">
                    <DataTable style={{width: '1000px'}}
                              value={this.state.respAudit} responsiveLayout="scroll" showGridlines header="Audit" dataKey="id" paginator rows={10}  rowsPerPageOptions={[5, 10, 25]}>
                        <Column field="timesheet_id" header="timesheet id"></Column>
                        <Column field="user" header="User"></Column>
                        <Column field="before" header="Before"></Column>
                        <Column field="after" header="After"></Column>
                        <Column field="date" header="Date"></Column>
                    </DataTable >
                </div>
            </div>
            </Dialog>
        );
        const timesheetDialogFooter = (
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" disabled={!this.state.chargedYet} className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" loading={!this.state.chargedYet} onClick={this.updateTimeSheet} />
            </React.Fragment>
        );
        const timesheetDialog = (
            <div>
                <Dialog visible={this.state.timesheetDialog} style={{ width: '650px' }} header="Details" modal
                        className="p-fluid" footer={timesheetDialogFooter} onHide={this.hideDialog}>
                    <div className="p-field">
                        <table className="custom-table striped no-min ta-left">
                            <thead><tr></tr></thead>
                            <tbody>
                                <tr><td className="textBold">Name</td>
                                    <td>
                                        {this.state.dataTableSelectValue.first_name} - {this.state.dataTableSelectValue.last_name}
                                    </td>
                                </tr>
                                <tr><td className="textBold">Work Order</td>
                                    <td>{this.state.dataTableSelectValue.work_order}</td>
                                </tr>
                                <tr><td className="textBold">Customer</td>
                                    <td>{this.state.dataTableSelectValue.customer}</td>
                                </tr>
                                <tr>
                                    <td className="textBold">Date</td>
                                    <td>{this.state.dataTableSelectValue.date}</td>
                                </tr>
                                <tr><td className="textBold">Total Hours</td>
                                    <td>{this.state.dataTableSelectValue.total_hours_f}</td>
                                </tr>
                                <tr><td className="textBold">Type</td>
                                    <td>{this.state.dataTableSelectValue.type}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="p-field">
                        <label htmlFor="qty_hours_fe_f">Qty Hours FE / Corrigo</label>
                        <InputText name="qty_hours_fe_f" value={this.state.dataTableSelectValue.qty_hours_fe_f} onChange={this.changeHandlerTimesheet} required autoFocus />
                    </div>
                    <div className="p-field">
                        <label htmlFor="notes">Notes</label>
                        <InputTextarea name="notes" value={this.state.dataTableSelectValue.notes} onChange={this.changeHandlerTimesheet} required rows={3} cols={20} maxLength={300}/>
                    </div>
                    <div className="p-field">
                        <label className="textBold">Hour difference: </label>
                        <label>{this.state.dataTableSelectValue.difference_timesheet_f}</label>
                    </div>
                </Dialog>
            </div>
        );

        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE"
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                            <div>
                                <div className="p-fluid grid">
                                    <div className="col-12 md:col-4">
                                        <Calendar id="range" value={this.state.datesFilter} name="datesFilter"
                                                  showIcon={true} showWeek={true} placeholder="Reporting Period: *"
                                                  onChange={this.changeHandler} selectionMode="range" readOnlyInput
                                                  className={this.state.errors?.datesFilter !== undefined ? "form-element p-invalid" : "form-element "}/>
                                        {this.state.errors?.datesFilter !== undefined ?
                                            <small className="offline">{this.state.errors?.datesFilter}</small>
                                            : ""
                                        }
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <Dropdown className="form-element"
                                                  placeholder="Service type"
                                                  name="serviceTypeForm"
                                                  options={this.serviceTypes}
                                                  value={this.state.serviceTypeForm}
                                                  showClear
                                                  onChange={this.changeHandler}
                                        />
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <AutoComplete
                                            className="form-element"
                                            placeholder="Select a technician"
                                            name="technicianForm"
                                            suggestions={this.state.filteredTechnicians}
                                            value={this.state.technicianForm}
                                            field="label" dropdown
                                            itemTemplate={this.templateTechnician}
                                            completeMethod={this.searchTechnicians}
                                            onChange={this.changeHandler}/>
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <AutoComplete
                                            className="form-element"
                                            placeholder="Select a Team"
                                            name="teamForm"
                                            suggestions={this.state.filteredTeams}
                                            value={this.state.teamForm}
                                            field="label" dropdown
                                            itemTemplate={this.templateTeam}
                                            completeMethod={this.searchTeams}
                                            onChange={this.changeHandler} />
                                    </div>
                                    <div className="col-12 md:col-4">
                                        <InputText
                                            placeholder="Work Order"
                                            name="workOrder"
                                            onChange={this.changeHandler}
                                            value={this.state.workOrder} />
                                        <small className="ta-right" style={{display: "block"}}>optional*</small>
                                    </div>
                                    <div className="col-12 md:col-4 ta-right">
                                        <div className="grid">
                                            <div className="col-2">
                                                <Button icon="pi pi-times" onClick={this.clearFilters}
                                                        tooltip="Clear filters" tooltipOptions={{position: 'top'}}/>
                                            </div>
                                            <div className="col-10 md:col-4">
                                                <Button label="Audit" onClick={this.auditTimesheets}/>
                                            </div>
                                            <div className="col-10 md:col-4">
                                                <Button label="Search" onClick={this.searchReport}/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <DataTable value={this.state.rawReport} rowGroupMode="subheader" groupRowsBy="header"
                                       selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) =>
                                           this.setState({dataTableSelectValue: e.value})}
                                       sortField="header" groupField="header" dataKey="id"
                                       rowGroupHeaderTemplate={this.headerTemplate}
                                       rowGroupFooterTemplate={this.footerTemplate}
                                       showGridlines selectionMode="single"
                                       scrollable scrollHeight="550px" scrollDirection="both"
                                       tableClassName="slim-table">
                                <Column field="fullName" sortable header="Name" className="ta-center-block"
                                        style={{fontSize: 13, width: 200}}/>
                                <Column field="work_order" sortable header="Work Order #" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="customer" sortable header="Customer Name" className="ta-center-block"
                                        style={{fontSize: 13, width: 120}}/>
                                <Column field="date" sortable header="Date of service Call" className="ta-center-block"
                                        style={{fontSize: 13, width: 150}}/>
                                <Column field="after_hours" sortable header="After Hours" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="per_diem" sortable header="Per Diem" className="ta-center-block"
                                        style={{fontSize: 13, width: 80}}/>
                                <Column field="pto" sortable header="PTO" className="ta-center-block"
                                        style={{fontSize: 13, width: 80}}/>
                                <Column field="work_started" sortable header="Started" className="ta-center-block"
                                        style={{fontSize: 13, width: 120}}/>
                                <Column field="work_ended" sortable header="Ended" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="total_hours_f" sortable header="Total Hours" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="work_st" sortable header="ST Hours" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="work_ot" sortable header="OT Hours" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="work_pto" sortable header="Requested PTO" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="difference_timesheet_f" sortable header="Difference TimeSheets/Platforms"
                                        className="ta-center-block" style={{fontSize: 13, width: 200}}/>
                                <Column field="address" sortable header="Address" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="d_created" sortable header="Entry Created on" className="ta-center-block"
                                        style={{fontSize: 13, width: 160}}/>


                                <Column field="qty_hours_fe_f" sortable header="Qty Hours in FE/Corrigo"
                                        className="ta-center-block" style={{fontSize: 13, width: 100}}/>
                                <Column field="type" sortable header="Service Type" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="notes" sortable header="Notes WO FE & Corrigo" className="ta-center-block"
                                        style={{fontSize: 13, width: 300}}/>
                                <Column body={this.actionBodyTemplate} exportable={false} className="ta-center-block"
                                        style={{minWidth: '4rem', width: 40}}></Column>
                            </DataTable>
                            {this.state.rawReport.length > 0 &&
                                <div className="custom-table-footer">
                                    <label className="textBold">
                                        Total Rows: {this.state.rawReport.length}
                                    </label>
                                </div>
                            }
                        </div>
                    </div>
                </Card>
                {timesheetDialog}
                {auditTimesheetsDialog}
            </div>
        )
    }
}

export default Report;
