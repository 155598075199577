import React, {Component} from "react";
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {SelectButton} from "primereact/selectbutton";
import {isEmpty, rounded } from "../utils/commonFunctions";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {AutoComplete} from "primereact/autocomplete";

class Sheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: false,
            reports: [],
            customers: [],
            filteredCustomer: null,
            report: {
                id: null,
                profile: "",
                profile_desc: "",
                work_order: "",
                customer_data: "",
                date: "",
                after_hours: "",
                per_diem: 0,
                pto: 0,
                work_started: "",
                work_ended: "",
                total_hours: "",
                total_hours_f: 0,
                work_st: "",
                work_ot: "",
                work_pto: "",
                work_diff: "",
                d_created: "",
                reason_id: '',
            },
            dataTableSelectValue: null,
            recipientActionType: null,
            currentLink: "",
            nextLink: null,
            previousLink: null,
            totalRow: 0,
            pageCurrent: 0,
            totalPage: 0,
            actionToConfirm: null,
            processingAction: false,
            displayConfirm: false,
            deleteSheetDialog: false,
            addUpdateDialog: false,
            errors: {},
            reasonsNoWO: [],
        }
        this.options = [
            {name: 'No', value: "N"},
            {name: 'Yes', value: "Y"}
        ];

        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.callTimesheet = this.callTimesheet.bind(this);
        this.previousPage = this.previousPage.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.changeHandlerReport = this.changeHandlerReport.bind(this);
        this.addUpdateDialog = this.addUpdateDialog.bind(this);
        this.deleteSheetDialog = this.deleteSheetDialog.bind(this);
        this.validate = this.validate.bind(this);
        this.closeConfirmModal = this.closeConfirmModal.bind(this);
        this.loadCatalogCustomers = this.loadCatalogCustomers.bind(this);
        this.templateCustomers = this.templateCustomers.bind(this);
        this.searchCustomers = this.searchCustomers.bind(this);
        this.worxflowServices = new WorxflowServices();
        this.minDate = new Date();
        this.maxDate = new Date();
    }

    componentDidMount() {
        this.loadCatalogCustomers();
        this.worxflowServices.getReasonList().then(resp => {
            let reasonsNoWO = [];
            resp.data.forEach(reason => {
                if(reason.status === "1"){
                    reason.label = reason.name;
                    reason.value = reason.id;
                    reasonsNoWO.push(reason);
                }
            });
            this.setState({reasonsNoWO});

        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });
    }
    loadCatalogCustomers() {
        const customers = JSON.parse(localStorage.getItem('customers'));
        if(customers == null){
            this.worxflowServices.getCustomerList().then(resp => {
                if (resp.status === "OK") {
                    resp.data.forEach(d => {
                        d.label = d.name;
                        d.value = d.id;
                    })
                    this.setState({customers: resp.data});
                    localStorage.setItem('customers', JSON.stringify(resp.data));
                    this.callTimesheet();
                }
            }).catch(error => {
                console.error("Error on service: ", error);
            });
        } else {
            this.setState({customers});
            this.callTimesheet();
        }
    }
    calculateMinMaxDateCalendar() {
        let today = new Date();
        // 0 - Dom : restar 3 - sumar 4
        // 1 - Lun : restar 4 - sumar 3
        // 2 - Mar : restar 5 - sumar 2
        // 3 - Mie : restar 6 - sumar 1
        // 4 - Jue : restar 0 - sumar 7
        // 5 - Vie : restar 1 - sumar 6
        // 6 - Sab : restar 2 - sumar 5
        switch (today.getDay()) {
            case 0:
                this.minDate.setDate(today.getDate() - 3);
                this.maxDate.setDate(today.getDate() + 4);
                break;
            case 1:
                this.minDate.setDate(today.getDate() - 4);
                this.maxDate.setDate(today.getDate() + 3);
                break;
            case 2:
                this.minDate.setDate(today.getDate() - 5);
                this.maxDate.setDate(today.getDate() + 2);
                break;
            case 3:
                this.minDate.setDate(today.getDate() - 6);
                this.maxDate.setDate(today.getDate() + 1);
                break;
            case 4:
                this.minDate.setDate(today.getDate() - 0);
                this.maxDate.setDate(today.getDate() + 7);
                break;
            case 5:
                this.minDate.setDate(today.getDate() - 1);
                this.maxDate.setDate(today.getDate() + 6);
                break;
            case 6:
                this.minDate.setDate(today.getDate() - 2);
                this.maxDate.setDate(today.getDate() + 5);
                break;
            default:
                break;
        }

        //activate calendar days
        this.minDate.setDate(today.getDate() - 365);
        this.maxDate.setDate(today.getDate() + 365);
    }
    callTimesheet(currentLink) {
        currentLink = currentLink === undefined ? "" : currentLink;
        this.worxflowServices.getTimesheetMobile(null, currentLink).then(resp => {
            let totalPage = resp.total / resp.page_size;
            totalPage = Math.trunc(totalPage);
            if(resp.total % resp.page_size > 0){
                totalPage += 1;
            }
            resp.results.forEach(element => {
                element.total_hours_f = rounded(element.total_hours_f)
                const reason = this.state.reasonsNoWO.find(e => e.id === element.reason_id);
                element.reason_desc = reason !== undefined ? reason.name : "";
            })
            this.setState({
                reports: resp.results,
                chargedYet: true,
                nextLink: resp.links.next,
                previousLink: resp.links.previous,
                totalRow: resp.total,
                pageCurrent: resp.page,
                totalPage,
                currentLink
            });
        }).catch(error => {
            this.setState({chargedYet: true});
            console.error("Error en el servicio: ", error);
        });
    }
    previousPage(){
        let tmp = this.state.previousLink.split('?');
        const currentLink = "?" + tmp[1];
        this.callTimesheet(currentLink);
    }
    nextPage(){
        let tmp = this.state.nextLink.split('?');
        const currentLink = "?" + tmp[1];
        this.callTimesheet(currentLink);
    }

    showModal = (modal, type) => () => {
        /*validation before show modal*/
        const {report, dataTableSelectValue} = this.state;
        this.calculateMinMaxDateCalendar();
        if (type === "new") {
            report.id = null;
            report.work_order = "";
            report.customer_data = "";
            report.date = "";
            report.after_hours = "N";
            report.per_diem = 0;
            report.pto = 0;
            report.work_started = "";
            report.work_ended = "";
            report.total_hours = "";
            report.total_hours_f = "";
            report.d_created = "";
            report.reason_id = "";
        } else {
            if (dataTableSelectValue === null) {
                this.toast.show({severity: 'error', summary: 'Error', detail: "No data selected."});
                return;
            }
            if(dataTableSelectValue.after_hours === "Y" || dataTableSelectValue.after_hours === "N"){
                report.after_hours = dataTableSelectValue.after_hours;
            } else {
                report.after_hours = "N"
            }

            report.id = dataTableSelectValue.id;
            report.work_order = dataTableSelectValue.work_order;
            report.total_hours_f = dataTableSelectValue.total_hours_f;
            report.d_created = dataTableSelectValue.d_created;
            report.date = new Date(dataTableSelectValue.date);
            report.per_diem = parseInt(dataTableSelectValue.per_diem)
            report.pto = parseInt(dataTableSelectValue.pto)
            report.per_diem = isNaN(report.per_diem) ? 0 : report.per_diem;
            report.pto = isNaN(report.pto) ? 0 : report.pto;
            //find customer
            report.customer_data = ""
            this.state.customers.forEach(customer => {
                if(customer.name === dataTableSelectValue.customer){
                    report.customer_data = customer;
                }
            })
            report.reason_id = dataTableSelectValue.reason_id;
        }

        if (type === "update"){
            try {
                let hours = 0;
                let minutes = 0;
                if(dataTableSelectValue.work_started.split(":").length >= 2){
                    let dateStarted = new Date();
                    hours = dataTableSelectValue.work_started.split(":")[0];
                    minutes = dataTableSelectValue.work_started.split(":")[1];
                    dateStarted.setHours(parseInt(hours));
                    dateStarted.setMinutes(parseInt(minutes));
                    report.work_started = dateStarted;
                }
                if(dataTableSelectValue.work_ended.split(":").length >= 2){
                    let dateEnded = new Date();
                    hours = dataTableSelectValue.work_ended.split(":")[0];
                    minutes = dataTableSelectValue.work_ended.split(":")[1];
                    dateEnded.setHours(parseInt(hours));
                    dateEnded.setMinutes(parseInt(minutes));
                    report.work_ended = dateEnded;
                }
                if(dataTableSelectValue.total_hours.split(":").length >= 2){
                    let dateTotalHours = new Date();
                    hours = dataTableSelectValue.total_hours.split(":")[0];
                    minutes = dataTableSelectValue.total_hours.split(":")[1];
                    dateTotalHours.setHours(parseInt(hours));
                    dateTotalHours.setMinutes(parseInt(minutes));
                    report.total_hours = dateTotalHours;
                }
            } catch (error){
                console.log("error: " + error)
            }
        }
        if(modal === "deleteSheetDialog") {
            report.date = dataTableSelectValue.date;
            report.work_started = dataTableSelectValue.work_started;
            report.work_ended = dataTableSelectValue.work_ended;
            report.total_hours = dataTableSelectValue.total_hours;
        }

        report.work_st = null;
        report.work_ot = null;
        report.work_pto = null;
        report.work_diff = null;

        this.setState({
            [modal]: true,
            recipientActionType: type
        });
    }
    hideModal = modal => () => {
        this.setState({
            [modal]: false,
            displayConfirm: false
        });
    }
    confirmationDialog = (nameModal, showModal, action) => {
        const params = {
            work_order: this.state.report.work_order,
            customer_data: this.state.report.customer_data,
            date: this.state.report.date,
            work_started: this.state.report.work_started,
            work_ended: this.state.report.work_ended,
            reason_id: this.state.report.reason_id
        };
        const errors = this.validate(params);
        if (Object.keys(errors).length === 0) {
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            })
        } else {
            this.setState({errors});
        }
    }
    validate = (data) => {
        let errors = {};
        // errors.recipient = {};

        if (!data.work_order && !data.reason_id) {
            errors.work_order = 'Work order is mandatory or add a reason.';
        }
        if (!data.customer_data) {
            errors.customer = 'Customer is mandatory.';
        }
        if (!data.date) {
            errors.date = 'Date is mandatory.';
        }
        if (!data.work_started) {
            errors.work_started = 'Work started is mandatory.';
        }
        if (!data.work_ended) {
            errors.work_ended = 'Work ended is mandatory.';
        }
        //errors = Object.keys(errors.recipient).length === 0 ? {} : errors;
        return errors;
    }
    changeHandlerReport = event => {
        const {errors, report} = this.state;

        if(event.target.name === 'reason_id') {
            errors['work_order'] = undefined;
        }

        if (event.target !== undefined && event.target.name !== undefined) {
            if (errors[event.target.name] !== undefined) {
                errors[event.target.name] = undefined;
            }
            report[event.target.name] = event.target.value;
        } else {
            if (errors[event.originalEvent.target.name] !== undefined) {
                errors[event.originalEvent.target.name] = undefined;
            }
            report[event.originalEvent.target.name] = event.originalEvent.target.value;
        }
        this.setState({report, errors});
    }
    addUpdateDialog(){
        console.log("addUpdateDialog")
        const {report, recipientActionType} = this.state;
        const content = JSON.parse(JSON.stringify(report));
        const strSeconds = ":00";
        let date = ("0" + (report.date.getDate())).slice(-2);
        let month = ("0" + (report.date.getMonth() + 1)).slice(-2);
        let year = report.date.getFullYear();
        content.date = year + "-" + month + "-" + date;
        content.customer = report.customer_data.name;
        content.customer_id = report.customer_data.id;
        if(!isEmpty(report.work_started)){
            content.work_started = report.work_started.getHours() + ":" + report.work_started.getMinutes() + strSeconds;
        }
        if(!isEmpty(report.work_ended)){
            content.work_ended = report.work_ended.getHours() + ":" + report.work_ended.getMinutes() + strSeconds;
        }
        if(!isEmpty(report.total_hours)){
            content.total_hours = report.total_hours.getHours() + ":" + report.total_hours.getMinutes() + strSeconds;
        }

        const params = {
            work_order: content.work_order,
            customer: content.customer,
            customer_id: content.customer_id,
            reason_id: content.reason_id === "" ? null: content.reason_id,
            date: content.date,
            after_hours: content.after_hours,
            per_diem: content.per_diem,
            pto: content.pto,
            work_started: content.work_started,
            work_ended: content.work_ended,
            total_hours: content.total_hours,
            work_st: content.work_st,
            work_ot: content.work_ot,
            work_pto: content.work_pto,
            work_diff: content.work_diff
        }

        if(recipientActionType === "update"){
            params.id = content.id;
            this.worxflowServices.updateTimesheet(params).then(resp => {
                if (resp.status === 200) {
                    this.toast.show({severity: 'success', summary: 'Success', detail: "Updated."});
                    this.closeConfirmModal();
                    this.callTimesheet();
                }
            }).catch(error => {
                console.error("Error on service: ", error);
            });
        } else {
            this.worxflowServices.addTimesheet(params).then(resp => {
                if (resp.status === 200) {
                    this.toast.show({severity: 'success', summary: 'Success', detail: "Saved."});
                    this.closeConfirmModal();
                    this.callTimesheet();
                }
            }).catch(error => {
                if (error.response?.data["msg-warning"] !== '') {
                    this.toast.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: error.response?.data["msg-warning"]
                    });
                }
                console.error("Error on service: ", error);
            });
        }
    }
    deleteSheetDialog() {
        const {report} = this.state;
        const params = {
            id: report.id
        }
        this.worxflowServices.deleteTimesheet(params).then(resp => {
            if (resp.status === 200) {
                this.toast.show({severity: 'success', summary: 'Success', detail: "Deleted."});
                this.closeConfirmModal();
                this.callTimesheet();
            }
        }).catch(error => {
            console.error("Error on service: ", error);
        });
    }
    closeConfirmModal(){
        this.setState({
            displayConfirm: false,
            addUpdateDialog: false,
            dataTableSelectValue: null,
            deleteSheetDialog: false,
            processingAction: false
        });
    }

    templateCustomers(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }
    searchCustomers(event) {
        setTimeout(() => {
            let filteredCustomer;
            if (!event.query.trim().length) {
                filteredCustomer = [...this.state.customers];
            } else {
                filteredCustomer = this.state.customers.filter((customer) => {
                    return customer.label.toLowerCase().includes(event.query.toLowerCase());
                });
            }
            this.setState({ filteredCustomer });
        }, 250);
    }
    render() {

        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processingAction}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processingAction}/>
            </div>
        );

        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true} style={{width: '50vw'}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel" className="p-button-secondary"/>
                <Button icon="pi pi-check" className={this.state.deleteSheetDialog ? "p-button-danger" : ""}
                        onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction} />
            </div>
        );

        const addUpdateDialog = (
            <Dialog header={this.state.recipientActionType === 'new' ? "Add Detail" : "Update Details"}
                    visible={this.state.addUpdateDialog} modal
                    style={{width: '850px'}} contentStyle={{height: 450}}
                    footer={dialogFooter('addUpdateDialog', this.addUpdateDialog,
                        this.state.recipientActionType === 'new' ? 'Add' : 'Update')}
                    onHide={this.hideModal('addUpdateDialog')}>
                <div className="grid p-fluid">
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>Work order: </b>
                        <InputText className={this.state.errors?.work_order !== undefined ? "form-element p-invalid" : "form-element"}
                            placeholder="Work order"
                            name="work_order"
                            onChange={this.changeHandlerReport}
                            value={this.state.report.work_order} />
                        {this.state.errors?.work_order !== undefined ?
                            <small className="offline">{this.state.errors?.work_order}</small>
                            : ""
                        }
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>Customer: </b>
                        <AutoComplete
                            className="form-element"
                            placeholder="Customer"
                            name="customer_data"
                            suggestions={this.state.filteredCustomer}
                            value={this.state.report.customer_data}
                            field="label" dropdown
                            itemTemplate={this.templateCustomers}
                            completeMethod={this.searchCustomers}
                            onChange={this.changeHandlerReport}/>
                        {this.state.errors?.customer !== undefined ?
                            <small className="offline">{this.state.errors?.customer}</small>
                            : ""
                        }
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>Date: </b>
                        <Calendar id="calendar" value={this.state.report.date}
                                  name="date" dateFormat="yy/mm/dd"
                                  minDate={this.minDate} maxDate={this.maxDate}
                                  disabled={this.state.recipientActionType !== 'new'}
                                  onChange={this.changeHandlerReport}
                                  className={this.state.errors?.date !== undefined ? "form-element p-invalid" : "form-element"} />
                        {this.state.errors?.date !== undefined ?
                            <small className="offline">{this.state.errors?.date}</small>
                            : ""
                        }
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>After hours: </b>
                        <SelectButton value={this.state.report.after_hours}
                                      name="after_hours"
                                      options={this.options}
                                      optionLabel="name"
                                      onChange={this.changeHandlerReport}
                                      className="button-blue"
                        />
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>Per diem: </b>
                        <InputNumber value={this.state.report.per_diem}
                                  name="per_diem"
                                  onValueChange={(e) => this.setState({"report.per_diem": e.value})}
                                  />
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>PTO: </b>
                        <InputNumber value={this.state.report.pto}
                                     name="pto"
                                     onValueChange={(e) => this.setState({"report.pto": e.value})}
                                    />
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>Work started: </b>
                        <Calendar className={this.state.errors?.work_started !== undefined ? "form-element p-invalid" : "form-element"}
                                  value={this.state.report.work_started}
                                  name="work_started"
                                  onChange={this.changeHandlerReport}
                                  timeOnly hourFormat="24" />
                        {this.state.errors?.work_started !== undefined ?
                            <small className="offline">{this.state.errors?.work_started}</small>
                            : ""
                        }
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>Work ended: </b>
                        <Calendar className={this.state.errors?.work_ended !== undefined ? "form-element p-invalid" : "form-element"}
                                  value={this.state.report.work_ended}
                                  name="work_ended"
                                  onChange={this.changeHandlerReport}
                                  timeOnly hourFormat="24" />
                        {this.state.errors?.work_ended !== undefined ?
                            <small className="offline">{this.state.errors?.work_ended}</small>
                            : ""
                        }
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6">
                        <b>Reason: </b>
                        <Dropdown className="form-element"
                                  placeholder="Reasons"
                                  name="reason_id"
                                  value={this.state.report.reason_id}
                                  options={this.state.reasonsNoWO}
                                  showClear
                                  onChange={this.changeHandlerReport}
                        />
                    </div>
                </div>
            </Dialog>
        );

        const deleteDialog = (
            <Dialog header="Delete timesheet" visible={this.state.deleteSheetDialog} modal
                    style={{width: '650px'}} contentStyle={{height: 200}}
                    footer={dialogFooter('deleteSheetDialog', this.deleteSheetDialog, 'Confirm Delete')}
                    onHide={this.hideModal('deleteSheetDialog')}>
                <div className="datail-transaction">
                    <div className="grid">
                        <div className="col-4"><b>profile_desc: </b>{this.state.report.profile}</div>
                        <div className="col-4"><b>Work order: </b>{this.state.report.work_order}</div>
                        <div className="col-4"><b>Customer: </b>{this.state.report.customer}</div>
                        <div className="col-4"><b>Date: </b>{this.state.report.date}</div>
                        <div className="col-4"><b>After hours: </b>{this.state.report.after_hours}</div>
                        <div className="col-4"><b>Per diem: </b>{this.state.report.per_diem}</div>
                        <div className="col-4"><b>PTO: </b>{this.state.report.pto}</div>
                        <div className="col-4"><b>Work started: </b>{this.state.report.work_started}</div>
                        <div className="col-4"><b>Work ended: </b>{this.state.report.work_ended}</div>
                        <div className="col-4"><b>Total hours: </b>{this.state.report.total_hours}</div>
                        <div className="col-4"><b>Hours F: </b>{this.state.report.total_hours_f}</div>
                        <div className="col-4"><b>Created: </b>{this.state.report.d_created}</div>
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE"
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                            <div className="headButtons">
                                <Button label="Add" onClick={this.showModal('addUpdateDialog', 'new')} />
                                <Button label="Update" onClick={this.showModal('addUpdateDialog', 'update')} className="p-button-info"/>
                                <Button label="Delete" onClick={this.showModal('deleteSheetDialog')} className="p-button-danger"/>
                                <div style={{float: "right"}}>
                                    <b style={{marginRight: '15px', fontSize: '1rem', color: '#007bff'}}>{`Total rows: ${this.state.totalRow}`}</b>
                                    <b style={{marginRight: '15px', fontSize: '1rem', color: '#007bff'}}>{`Page ${this.state.pageCurrent} of ${this.state.totalPage}`}</b>
                                    <Button label="Previous" onClick={this.previousPage}
                                            icon="pi pi-angle-left" disabled={this.state.previousLink === null ? true : false}
                                            className="p-button-secondary p-button-raised p-button-rounded" />
                                    <Button label="Next" onClick={this.nextPage}
                                            icon="pi pi-angle-right" iconPos="right" disabled={this.state.nextLink === null ? true : false}
                                            className="p-button-secondary p-button-raised p-button-rounded"/>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">

                            <DataTable value={this.state.reports} selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) =>
                                           this.setState({dataTableSelectValue: e.value})}
                                       selectionMode="single" dataKey="id" showGridlines
                                       scrollable scrollHeight="550px" loading={!this.state.chargedYet} scrollDirection="both">
                                <Column field="date" header="Date" className="ta-center" sortable style={{fontSize: 13, width: 100}}/>
                                <Column field="work_order" header="Order" className="ta-right" sortable style={{fontSize: 13, width: 200}}/>
                                <Column field="customer" header="Customer" className="ta-left" sortable style={{fontSize: 13, width: 200}}/>
                                <Column field="after_hours" header="After hours" className="ta-center" sortable style={{fontSize: 13, width: 100}}/>
                                <Column field="per_diem" header="Diem" className="ta-center" sortable style={{fontSize: 13, width: 100}}/>
                                <Column field="pto" header="PTO" className="ta-center" sortable style={{fontSize: 13, width: 100}}/>
                                <Column field="work_started" header="Started" className="ta-center" sortable style={{fontSize: 13, width: 85}}/>
                                <Column field="work_ended" header="Ended" className="ta-center" sortable style={{fontSize: 13, width: 85}}/>
                                <Column field="total_hours_f" header="Hours" className="ta-center" sortable style={{fontSize: 13, width: 60}}/>
                                <Column field="reason_desc" sortable header="Reason" className="ta-center" style={{fontSize: 13, width: 100}}/>
                                <Column field="d_created" header="Created" className="ta-center" sortable style={{fontSize: 13, width: 160}}/>
                            </DataTable>
                        </div>
                    </div>
                </Card>
                {confirmDialog}
                {addUpdateDialog}
                {deleteDialog}
            </div>
        )
    }
}

export default Sheet;
