import React, {Component} from "react";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {rounded} from "../utils/commonFunctions";
import {Calendar} from "primereact/calendar";
import {DateService} from "../../service/util/DateService";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";

class Report extends Component {
    serviceTypes = [
        {label: 'Service', value: 'SE'},
        {label: 'Construction', value: 'CT'},
        {label: 'PM', value: 'PM'}
    ];
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: false,
            datesFilter: [],
            weeks: [],
            serviceTypeForm: "",
            filteredWeeks: null,
            weekForm: null,
            weekSelect: undefined,
            workOrder: '',
            report: [],
            daysProcessed: [],
            rawReport: [],
            dataTableSelectValue: null,
            errors: {},
        }

        this.searchReport = this.searchReport.bind(this);
        this.templateWeek = this.templateWeek.bind(this);
        this.searchWeeks = this.searchWeeks.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.headerTemplate = this.headerTemplate.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        this.dateTemplate = this.dateTemplate.bind(this);
        this.worxflowServices = new WorxflowServices();
        this.dateService = new DateService();
    }

    componentDidMount() {
        this.setState({chargedYet: true});
    }
    searchReport = () => {
        if ((this.state.datesFilter !== null && this.state.datesFilter.length === 2)
            || this.state.workOrder.length > 2) {
            const params = {
                date_start: this.dateService.dateToStringFront3(this.state.datesFilter[0]),
                date_end: this.dateService.dateToStringFront3(this.state.datesFilter[1]),
                email: null,
                team_id: null,
                type: this.state.serviceTypeForm === undefined ? '' : this.state.serviceTypeForm,
                work_order: this.state.workOrder
            };

            this.worxflowServices.searchReportTimesheet(params).then(resp => {
                if (resp.status_code === 200) {
                    const rawReport = [];
                    resp.data.forEach(element => {
                        let dataHeader = "Week:" + element.semana +
                            " Start: " + element.fecha_inicial +
                            " End: " + element.fecha_final +
                            // " Total Hours: " + element.total_hours +
                            " Total Hours: " + rounded(element.total_hours_f);
                        if (element.datos === null || element.datos.length === 0) {
                            element.header = dataHeader;
                            element.footerTotalHoursF = rounded(element.total_hours_f);
                            element.footerTotalPerDiem = element.total_per_diem;
                            element.footerTotalPto = element.total_pto;
                            if(element.first_name !== undefined)
                                element.fullName = element.first_name + " " + element.last_name;
                            rawReport.push(element);
                        }
                        element.datos.forEach(e => {
                            e.header = dataHeader;
                            e.footerTotalHoursF = rounded(element.total_hours_f);
                            e.footerTotalPerDiem = element.total_per_diem;
                            e.footerTotalPto = element.total_pto;
                            e.total_hours_f = rounded(e.total_hours_f);
                            e.fullName = e.first_name + " " + e.last_name;
                            rawReport.push(e);
                        });
                    });
                    this.setState({rawReport, report: resp.data});
                }
                this.setState({chargedYet: true});
            }).catch(error => {
                this.setState({chargedYet: true});
                console.error("Error en el servicio: ", error);
            });
        } else {
            this.toast.show({severity: 'error', summary: 'Error', detail: "Fill date o Work Order"});
        }
    }
    changeHandler = event => {
        const {errors} = this.state;
        if (errors[event.target.name] !== undefined) {
            errors[event.target.name] = undefined;
        }
        this.setState({
            [event.target.name]: event.target.value,
            errors
        });
        if(event.target.name === 'weekForm'){
            this.setState({
                weekForm: event.target.value,
                weekSelect: event.target.value
            });
        } else {
            this.setState({[event.target.name]: event.target.value, errors});
        }
    }
    headerTemplate(data) {
        return (
            <span key={data.id + '_headerLabel'} className="header_timesheet" style={{fontWeight: "bold"}}>
                {data.header}
            </span>
        );
    }
    footerTemplate(data, index) {
        return (
            <React.Fragment>
                <td key={data.id + '_footerLabel'} colSpan={5}
                    style={{textAlign: 'right', display: 'block', width: 670}}>
                    Totals
                </td>
                <td style={{textAlign: 'center', display: 'block', width: 80}}>{data.footerTotalPerDiem}</td>
                <td style={{textAlign: 'center', display: 'block', width: 80}}>{data.footerTotalPto}</td>
                <td style={{width: 120}}/>
                <td style={{width: 100}}/>
                <td style={{textAlign: 'center', display: 'block', width: 100}}>{data.footerTotalHoursF}</td>
                <td style={{width: 150}}/>
                <td style={{width: 100}}/>
            </React.Fragment>
        );
    }
    templateWeek(item) {
        return (
            <div className="country-item">
                <div id={item.value}>{item.label}</div>
            </div>
        );
    }
    searchWeeks(event) {
        setTimeout(() => {
            let filteredWeeks;
            if (!event.query.trim().length) {
                filteredWeeks = [...this.state.weeks];
            } else {
                filteredWeeks = this.state.weeks.filter((week) => {
                    return (week.label.toString()).toLowerCase().includes((event.query.toString()).toLowerCase());
                });
            }
            this.setState({ filteredWeeks });
        }, 250);
    }
    dateTemplate(date) {
        let isExists = false;
        for (let i = 0; i < this.state.daysProcessed.length; i++) {
            if(this.state.daysProcessed[i].getFullYear() === date.year &&
                this.state.daysProcessed[i].getMonth() === date.month &&
                this.state.daysProcessed[i].getDate() === date.day){
                isExists = true;
            }
        }
        if (isExists) {
            return (
                <div style={{backgroundColor: '#1dcbb3', color: '#ffffff', borderRadius: '10%', padding: '1px 0px 2px 0px'}}>{date.day}</div>
            );
        }
        else {
            return date.day;
        }
    }

    clearFilters = () => {
        this.setState({
            datesFilter: [],
            workOrder: '',
            serviceTypeForm: '',
        })
    }
    render() {
        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el} />
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE"
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                        <div className="col-12">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-3">
                                    <Calendar id="range" value={this.state.datesFilter} name="datesFilter"
                                              showIcon={true} showWeek={true} placeholder="Reporting Period: *"
                                              onChange={this.changeHandler} selectionMode="range" readOnlyInput
                                              className="form-element p-invalid"/>
                                        <small className="offline">Select a date range</small>
                                </div>
                                <div className="col-12 md:col-3">
                                    <Dropdown className="form-element"
                                              placeholder="Service type"
                                              name="serviceTypeForm"
                                              options={this.serviceTypes}
                                              value={this.state.serviceTypeForm}
                                              showClear
                                              onChange={this.changeHandler}
                                    />
                                </div>
                                <div className="col-12 md:col-3">
                                    <InputText
                                        placeholder="Work Order"
                                        name="workOrder"
                                        onChange={this.changeHandler}
                                        value={this.state.workOrder}
                                    />
                                </div>
                                <div className="col-12 md:col-3">
                                    <div className="grid">
                                        <div className="col-2">
                                            <Button icon="pi pi-times" onClick={this.clearFilters}
                                                    tooltip="Clear filters" tooltipOptions={{position: 'top'}}/>
                                        </div>
                                        <div className="col-10">
                                            <Button label="Search" onClick={this.searchReport}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <DataTable value={this.state.rawReport} rowGroupMode="subheader" groupRowsBy="header"
                                       selection={this.state.dataTableSelectValue}
                                       onSelectionChange={(e) =>
                                           this.setState({dataTableSelectValue: e.value})}
                                       sortField="header" groupField="header" dataKey="id"
                                       rowGroupHeaderTemplate={this.headerTemplate}
                                       rowGroupFooterTemplate={this.footerTemplate}
                                       showGridlines selectionMode="single"
                                       scrollable scrollHeight="550px" scrollDirection="both"
                                       tableClassName="slim-table">
                                <Column field="fullName" sortable header="Name" className="ta-center-block"
                                        style={{fontSize: 13, width: 200}}/>
                                <Column field="work_order" sortable header="Work Order #" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="customer" sortable header="Customer Name" className="ta-center-block"
                                        style={{fontSize: 13, width: 120}}/>
                                <Column field="date" sortable header="Date of service Call" className="ta-center-block"
                                        style={{fontSize: 13, width: 150}}/>
                                <Column field="after_hours" sortable header="After Hours" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="per_diem" sortable header="Per Diem" className="ta-center-block"
                                        style={{fontSize: 13, width: 80}}/>
                                <Column field="pto" sortable header="PTO" className="ta-center-block"
                                        style={{fontSize: 13, width: 80}}/>
                                <Column field="work_started" sortable header="Started" className="ta-center-block"
                                        style={{fontSize: 13, width: 120}}/>
                                <Column field="work_ended" sortable header="Ended" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="total_hours_f" sortable header="Total Hours" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                                <Column field="address" sortable header="Address" className="ta-center-block"
                                        style={{fontSize: 13, width: 150}}/>
                                <Column field="type" sortable header="Service Type" className="ta-center-block"
                                        style={{fontSize: 13, width: 100}}/>
                            </DataTable>
                            {this.state.rawReport.length > 0 &&
                                <div className="custom-table-footer">
                                    <label className="textBold">
                                        Total Rows: {this.state.rawReport.length}
                                    </label>
                                </div>
                            }
                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}

export default Report;
