import React, {Component} from "react";
import {Toast} from 'primereact/toast';
import {ProgressSpinner} from "primereact/progressspinner";
import {Button} from "primereact/button";
import {WorxflowServices} from "../../service/worxflow/WorxflowServices";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {isEmpty} from "../utils/commonFunctions";
import {Card} from "primereact/card";
import {Dropdown} from "primereact/dropdown";
import {Password} from "primereact/password";
import { InputSwitch } from 'primereact/inputswitch';
import {Toolbar} from "primereact/toolbar";


class Contacts extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chargedYet: false,
            contacts: [],
            dataTableSelectValue: [],
            actionToConfirm: null,
            recipientActionType: null,
            globalFilter: null,
            selectedRol: null,
            selectedTeam: null,
            addRecipientDialog: false,
            updateRecipientDialog: false,
            resetRecipientDialog: false,
            sendMessageDialog: false,
            recipient: {email: '', password: '', repeatPassword: '', last_name: '', first_name: '', phone_number: '', selectedRol_id: '', selectedTeam_id: ''},
            sendMessageText: '',
            countMessageText: 0,
            processingAction: false,
            errors: {},
            displayConfirm: false,
            value: null,
            selectedActive: false,

        }

        this.changeRecipientHandler = this.changeRecipientHandler.bind(this);
        this.changeRol = this.changeRol.bind(this);
        this.changeIsActive = this.changeIsActive.bind(this);
        this.changeTeam = this.changeTeam.bind(this);
        this.showModal = this.showModal.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.confirmationDialog = this.confirmationDialog.bind(this);
        this.callRecipientList = this.callRecipientList.bind(this);
        this.addRecipient = this.addRecipient.bind(this);
        this.updateRecipient = this.updateRecipient.bind(this);
        this.optionList_team_role = this.optionList_team_role.bind(this);
        this.isActive = this.isActive.bind(this);
        this.resetRecipient = this.resetRecipient.bind(this);
        this.communicationRecipient = this.communicationRecipient.bind(this);
        this.exportCSV = this.exportCSV.bind(this);
        this.rightToolbarTemplate = this.rightToolbarTemplate.bind(this);
        this.leftToolbarTemplate = this.leftToolbarTemplate.bind(this);




        this.validate = this.validate.bind(this);
        this.worxflowServices = new WorxflowServices();
    }

    componentDidMount() {
        this.callRecipientList();
        this.optionList_team_role();
    }

    callRecipientList() {
        this.worxflowServices.techniciansList().then(resp => {
            if (resp.status === 200) {
                const listcontacts = resp.data;
                this.setState({contacts: listcontacts.data,
                    chargedYet: true
                });
            }
        }).catch(error => {
            console.error("Error en el servicio: ", error);
        });

    }
    changeRol = e => {
        this.setState({selectedRol: e.value});
    }

    changeIsActive = e => {
        this.setState({selectedActive: e.value});
    }
    changeTeam = e => {
        this.setState({selectedTeam: e.value});
    }
    changeRecipientHandler = event => {
        const {recipient, errors} = this.state;
        recipient[event.target.name] = event.target.value;

        if (errors.recipient !== undefined && errors.recipient[event.target.name] !== undefined) {
            errors.recipient[event.target.name] = undefined;
        }
        this.setState({recipient, errors});
    };
    changePhoneHandler = event => {
        const {recipient, errors} = this.state;
        recipient.phone = event;
        if (errors.recipient?.phone !== undefined) {
            errors.recipient.phone = undefined;
        }
        this.setState({recipient, errors});
    };


    isActive (type) {
        return (
            <React.Fragment>
                <InputSwitch checked={type.is_active}  />
            </React.Fragment>
        );
    }
    showModal = (modal, type) => () => {
        debugger
        /*validation before show modal*/
        const {recipient, dataTableSelectValue} = this.state;

        if (modal === 'deleteRecipientDialog') {
            const errors = this.validate(modal);
            if (Object.keys(errors).length === 0) {
                this.setState({
                    [modal]: true,
                    recipientActionType: type
                });
            }
        } else if (modal === 'sendMessageDialog') {
            this.setState({
                [modal]: true,
                recipientActionType: type
            });
        } else {
            if (type === 'new' || dataTableSelectValue.length === 1) {
                if (type === 'update') {
                    recipient.id = dataTableSelectValue[0].id;
                    recipient.username = dataTableSelectValue[0].username;
                    recipient.last_name = dataTableSelectValue[0].last_name;
                    recipient.email = dataTableSelectValue[0].email;
                    recipient.first_name = dataTableSelectValue[0].first_name;
                    recipient.selectedRol = dataTableSelectValue[0].rol;
                    recipient.selectedTeam = dataTableSelectValue[0].team;
                    recipient.phone_number = dataTableSelectValue[0].phone_number;
                    recipient.password = dataTableSelectValue[0].password;
                    recipient.selectedActive = dataTableSelectValue[0].is_active;
                    recipient.newPassword = dataTableSelectValue[0].newPassword
                    recipient.pwd = dataTableSelectValue[0].pwd

                    this.setState({selectedActive: dataTableSelectValue[0].is_active});

                    this.state.listRol.forEach((rol, index) => {
                        if (rol.id === dataTableSelectValue[0].rol_id) {
                            return this.setState({selectedRol: rol});
                        }
                    });
                    this.state.listTeam.forEach((team, index) => {
                        if (team.id === dataTableSelectValue[0].team_id) {
                            return this.setState({selectedTeam: team});
                        }
                    });
                }
                this.setState({
                    [modal]: true,
                    recipientActionType: type,
                    recipient
                });
            } else {
                this.toast.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Select only one row.'
                });
            }
        }
    }
    hideModal = modal => () => {
        if (modal !== "displayConfirm") {
            this.setState({recipient: {name: '', last_name: '', phone: ''}});
        }
        this.setState({
            [modal]: false,
            displayConfirm: false,
            sendMessageText: '',
            errors: {},
            selectedTeam: null
        });
    }
    confirmationDialog = (nameModal, showModal, action) => {
        /*validation before to confirm*/
        const errors = this.validate(nameModal);
        if (Object.keys(errors).length === 0) {
            this.setState({
                [showModal]: true,
                actionToConfirm: action
            })
        } else {
            this.setState({errors});
        }
    }

    addRecipient() {
        this.setState({processingAction: true});
        const {recipient} = this.state;
        const params = {
            "email": recipient.email,
            "password": recipient.password,
            "first_name": recipient.first_name,
            "last_name": recipient.last_name,
            "team_id": recipient.selectedTeam_id
        };
        this.worxflowServices.addRecipientTechnician(params).then(resp => {
            this.setState({addRecipientDialog: false, processingAction: false, displayConfirm: false, selectedRol: null, recipient: [], selectedTeam: null});
            this.callRecipientList();
        }).catch(error => {
            this.setState({processingAction: false, selectedTeam: null});
            console.error("Error en el servicio: ", error);
        });
    }

    resetRecipient() {
        debugger
        const nameModal = 'resetRecipientDialog';
        this.validate(nameModal);
        const errors = this.validate(nameModal);
        if (Object.keys(errors).length === 0) {
        this.setState({processingAction: true});
        const {recipient} = this.state;
        const params = {
            "id": recipient.id,
            "new_password1": recipient.newPassword,
            "new_password2": recipient.newPassword,

        };
        debugger
        this.worxflowServices.resetRecipientTechnician(params).then(resp => {
                this.toast.show({severity: 'success', summary: 'Success reset password', detail: resp["message"]});
                this.setState({resetRecipientDialog: false, processingAction: false, displayConfirm: false, recipient: [], selectedTeam: null});
                this.callRecipientList();
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "This password is too short. It must contain at least 8 characters." +
                    "Password must not be similar to username",
                life: 5000
            })
            this.setState({resetRecipientDialog: false, processingAction: false, displayConfirm: false, recipient: [], selectedTeam: null});
            this.callRecipientList();

            });
    } else {
            this.setState({errors});
        }
        }

    communicationRecipient() {
        this.setState({processingAction: true});
        const {recipient} = this.state;
        const params = {
            "username": recipient.username,
            "pwd": recipient.pwd
        };
        this.worxflowServices.communicationRecipientTechnician(params).then(resp => {
            this.toast.show({severity: 'success', summary: 'Success', detail: resp["message"]});
            this.setState({communicationRecipientDialog: false, processingAction: false, displayConfirm: false, recipient: [], selectedTeam: null});
            this.callRecipientList();
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: "No communication",
                life: 5000
            })
            this.setState({communicationRecipientDialog: false,
                displayConfirm: false, recipient: [], selectedTeam: null});
        });
    }

    updateRecipient() {
        this.setState({processingAction: true});
        const {recipient} = this.state;
        const params = {
            "id": recipient.id,
            "first_name": recipient.first_name,
            "last_name": recipient.last_name,
            "team_id": this.state.selectedTeam.id,
            "is_active": this.state.selectedActive
        };
        this.worxflowServices.updateRecipientTechnician(params).then(resp => {
            this.toast.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Update Successful!',
            });
            this.setState({
                updateRecipientDialog: false,
                dataTableSelectValue: [],
                processingAction: false,
                displayConfirm: false,
                recipient: [],
                selectedTeam: null
            });
            this.callRecipientList();
        }).catch(error => {
            this.setState({addRecipientProcessing: false,recipient: [], selectedTeam: null});
            console.error("Error en el servicio: ", error);
        });
    }

    sendMessageHandler = () => {
        this.setState({processingAction: true});

        const {dataTableSelectValue, sendMessageText} = this.state;
        const contactsID = [];
        dataTableSelectValue.forEach(element => {
            contactsID.push(element.id);
        });
        const params={
            ids: contactsID,
            txt: sendMessageText,
        };

        this.worxflowServices.sendSMSRecipient(params).then(resp => {
            this.toast.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Messages sent!',
                life: 5000
            });
            this.setState({
                sendMessageDialog: false,
                dataTableSelectValue: [],
                processingAction: false,
                displayConfirm: false
            });
            this.callRecipientList();
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Problem in service!',
                life: 5000
            });
            this.setState({processingAction: false});
            console.error("Error en el servicio: ", error);
        });

    };

    validate(nameModal) {
        const {recipient} = this.state;
        recipient.selectedActive = this.state.selectedActive
        recipient.selectedTeam_id = this.state.selectedTeam.id
        let errors = {};
        errors.recipient = {};
        switch (nameModal) {
            case 'addRecipientDialog':
                if (isEmpty(recipient.email))
                    errors.recipient.email = 'Email name is mandatory.';
                if (isEmpty(recipient.last_name))
                    errors.recipient.last_name = 'Last Name  is mandatory.';
                if (isEmpty(recipient.first_name))
                    errors.recipient.first_name = 'First Name  is mandatory.';
                if (isEmpty(recipient.selectedTeam_id))
                    errors.recipient.selectedTeam = 'Team is mandatory.';
                if (isEmpty(recipient.password))
                    errors.recipient.password = 'Password is mandatory.';
                if (isEmpty(recipient.repeatPassword))
                    errors.recipient.repeatPassword = 'Password is mandatory.';
                if (recipient.password !== recipient.repeatPassword)
                    errors.recipient.repeatPassword = 'Passwords does not match.';
                break;
            case 'updateRecipientDialog':
                if (isEmpty(recipient.selectedRol_id))
                    errors.recipient.selectedRol = 'Rol is mandatory.';
                if (isEmpty(recipient.selectedTeam_id))
                    errors.recipient.selectedTeam = 'Team is mandatory.';
                break;
            case 'resetRecipientDialog':
                debugger
                if (isEmpty(recipient.newPassword))
                    errors.recipient.newPassword = 'New Password is mandatory.';
                if (isEmpty(recipient.repeat_newPassword))
                    errors.recipient.repeat_newPassword = 'Repeat New Password is mandatory.';
                if (recipient.newPassword !== recipient.repeat_newPassword)
                    errors.recipient.repeat_newPassword = 'Passwords does not match.';
                break;
            default:
                break
        }
        errors = Object.keys(errors.recipient).length === 0 ? {} : errors;
        return errors;
    }

    optionList_team_role () {
    this.worxflowServices.getRolList().then(resp => {
        const listRol = resp;
        this.setState({listRol});
        console.log(listRol)
     }).catch(error => {
        this.toast.show({
            severity: 'error',
            summary: 'Error',
            detail: 'Problem in service!',
            life: 5000
        });
    });
    this.worxflowServices.getTeamList().then(resp => {
        const listTeam = resp;
        this.setState({listTeam});
            console.log(listTeam)
        }).catch(error => {
            this.toast.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Problem in service!',
                life: 5000
            });
        });
    }


    rightToolbarTemplate() {
        return (
            <React.Fragment>
                <Button label="Export" icon="pi pi-upload" className="p-button-help" onClick={this.exportCSV} />
            </React.Fragment>
        )
    }


    exportCSV() {
        this.dt.exportCSV();
    }

    leftToolbarTemplate() {
        return (
            <div className="headButtons">
                <Button label="Add" onClick={this.showModal('addRecipientDialog', 'new')}
                        className="p-button-success"/>
                <Button label="Update" onClick={this.showModal('updateRecipientDialog', 'update')}
                        className="p-button-info"/>
                <Button label="Reset Password"
                        className="p-button-help" onClick={this.showModal('resetRecipientDialog', 'update')}/>
                <Button label="Communication"
                        className="p-button-warning" onClick={this.showModal('communicationRecipientDialog', 'update')}/>
            </div>
        )
    }


    render() {
        const {errors} = this.state;

        const renderFooterConfirm = (nameModal, action) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary" disabled={this.state.processingAction}/>
                <Button icon="pi pi-check" onClick={action} label="Confirm" disabled={this.state.processingAction}/>
            </div>
        );

        const confirmDialog = (
            <Dialog header="Are you sure?" visible={this.state.displayConfirm} modal={true}
                    style={{width: '50vw'}}
                    footer={renderFooterConfirm('displayConfirm', this.state.actionToConfirm)}
                    onHide={this.hideModal('displayConfirm')}>
                <p>Are you, continue!</p>
            </Dialog>
        );

        const header = (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="p-button-raised pi pi-search"/>
                    <InputText type="search"
                               onInput={(e) => this.setState({globalFilter: e.target.value})}
                               placeholder="Buscar..."/>
                </span>
            </div>
        );

        const dialogFooter = (nameModal, action, textAction) => (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal(nameModal)} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check" className={this.state.deleteRecipientDialog ? "p-button-danger" : ""}
                        onClick={() => this.confirmationDialog(nameModal, 'displayConfirm', action)}
                        label={textAction} />
            </div>
        );

        const dialogFooterUpdate = (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal('updateRecipientDialog')} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check"   className="p-button-primary" onClick={this.updateRecipient}
                        label="Update" />
            </div>
        );

        const dialogFooterReset = (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal('resetRecipientDialog')} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check"  className="p-button-primary" onClick={this.resetRecipient}
                        label="Reset" />
            </div>
        );

        const dialogFooterCommunication = (
            <div>
                <Button icon="pi pi-times" onClick={this.hideModal('communicationRecipientDialog')} label="Cancel"
                        className="p-button-secondary"/>
                <Button icon="pi pi-check"   className="p-button-primary" onClick={this.communicationRecipient}
                        label="Checking" />
            </div>
        );

        const addRecipientDialog = (
            <Dialog header={this.state.recipientActionType === 'new' ? "New Technician" : "Update Technician"}
                    visible={this.state.addRecipientDialog} modal
                    style={{width: '500px'}} contentStyle={{height: 320}}
                    footer={dialogFooter('addRecipientDialog',
                        this.state.recipientActionType === 'new' ? this.addRecipient : this.updateRecipient,
                        this.state.recipientActionType === 'new' ? 'Add' : 'Update')}
                    onHide={this.hideModal('addRecipientDialog')}>
                <div className="p-fluid datail-transaction pb-30">
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Email:</p>
                        <div className="col">
                            <InputText autocomplete="off" value={this.state.recipient.email} name="email"
                                       onChange={this.changeRecipientHandler}
                                       className={errors?.recipient?.email !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.name !== undefined ?
                                <small className="offline">{errors?.recipient?.email}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="grid p-fluid" >
                        <p className="small mt-7 textBold">Password:</p>
                        <div className="col">
                            <Password autocomplete="off" value={this.state.recipient.password} name="password" toggleMask
                                       onChange={this.changeRecipientHandler}
                                       className={errors?.recipient?.password !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.password !== undefined ?
                                <small className="offline">{errors?.recipient?.password}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid" >
                        <p className="small mt-7 textBold"></p>
                        <div className="col">
                            <Password value={this.state.repeatPassword}  placeholder="Confirm Password" name="repeatPassword" toggleMask
                                      onChange={this.changeRecipientHandler}
                                      className={errors?.recipient?.repeatPassword !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.repeatPassword !== undefined ?
                                <small className="offline">{errors?.recipient?.repeatPassword}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold"> First Name:</p>
                        <div className="col">
                            <InputText value={this.state.recipient.first_name} name="first_name"
                                       onChange={this.changeRecipientHandler}
                                       className={errors?.recipient?.first_name !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.first_name !== undefined ?
                                <small className="offline">{errors?.recipient?.first_name}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Last Name:</p>
                        <div className="col">
                            <InputText value={this.state.recipient.last_name} name="last_name"
                                       onChange={this.changeRecipientHandler}
                                       className={errors?.recipient?.last_name !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.last_name !== undefined ?
                                <small className="offline">{errors?.recipient?.last_name}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div  style={{display: "none"}} className="p-field grid">
                        <p className="small mt-7 textBold">Role:</p>
                        <div className="col">
                            <Dropdown style={{display: "none"}} options={this.state.listRol} value={this.state.selectedRol}
                                       onChange={this.changeRol} optionLabel="label" placeholder="Rol"
                                       className={errors?.recipient?.selectedRol !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.rol !== undefined ?
                                <small className="offline">{errors?.recipient?.rol}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Team:</p>
                        <div className="col">
                            <Dropdown options={this.state.listTeam} value={this.state.selectedTeam} optionLabel="label" placeholder="Team"
                                       onChange={this.changeTeam}
                                       className={errors?.recipient?.selectedTeam !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.team !== undefined ?
                                <small className="offline">{errors?.recipient?.team}</small>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const updateRecipientDialog = (
            <Dialog header={this.state.recipientActionType === 'new' ? "New Technician" : "Update Technician"}
                    visible={this.state.updateRecipientDialog} modal
                    style={{width: '500px'}} contentStyle={{height: 350}}
                    footer={dialogFooterUpdate}
                    onHide={this.hideModal('updateRecipientDialog')}>
                <div className="p-fluid datail-transaction pb-30">
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Email:</p>
                        <div className="col">
                            <InputText value={this.state.recipient.email} type="text" readonly/>
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Is active</p>
                        <div class="p-field-checkbox p-m-0 col" >
                            <InputSwitch checked={this.state.selectedActive}  onChange={this.changeIsActive}
                                       className={errors?.recipient?.selectedActive !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.name !== undefined ?
                                <small className="offline">{errors?.recipient?.email}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold"> First Name:</p>
                        <div className="col">
                            <InputText value={this.state.recipient.first_name} name="first_name"
                                       onChange={this.changeRecipientHandler}
                                       className={errors?.recipient?.first_name !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.first_name !== undefined ?
                                <small className="offline">{errors?.recipient?.first_name}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Last Name:</p>
                        <div className="col">
                            <InputText value={this.state.recipient.last_name} name="last_name"
                                       onChange={this.changeRecipientHandler}
                                       className={errors?.recipient?.last_name !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.last_name !== undefined ?
                                <small className="offline">{errors?.recipient?.last_name}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Role:</p>
                        <div className="col">
                            <InputText  value={this.state.recipient.selectedRol} readonly
                                      onChange={this.changeRol}
                                      className={errors?.recipient?.selectedRol !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.rol !== undefined ?
                                <small className="offline">{errors?.recipient?.rol}</small>
                                : ""
                            }
                        </div>
                    </div>
                    <div className="p-field grid">
                        <p className="small mt-7 textBold">Team:</p>
                        <div className="col">
                            <Dropdown options={this.state.listTeam} value={this.state.selectedTeam} optionLabel="label" placeholder="Team"
                                      onChange={this.changeTeam}
                                      className={errors?.recipient?.selectedTeam !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.team !== undefined ?
                                <small className="offline">{errors?.recipient?.team}</small>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            </Dialog>
        );

        const resetRecipientDialog = (
            <Dialog header={this.state.recipientActionType === 'new' ? "New Technician" : "Reset Password"}
                    visible={this.state.resetRecipientDialog} modal
                    style={{width: '560px'}} contentStyle={{height: 150}}
                    footer={dialogFooterReset}
                    onHide={this.hideModal('resetRecipientDialog')}>
                <div className="grid p-fluid">
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6" >
                        <p className="small mt-7 textBold"> New Password: </p>
                            <Password autocomplete="off" value={this.state.recipient.newPassword} name="newPassword" toggleMask
                                      onChange={this.changeRecipientHandler}
                                      className={errors?.recipient?.newPassword !== undefined ? "form-element p-invalid" : "form-element"} />
                            {errors?.recipient?.newPassword !== undefined ?
                                <small className="offline">{errors?.recipient?.newPassword}</small>
                                : ""
                            }
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6" >
                        <p className="small mt-7 textBold">Repeat New Password: </p>
                            <Password value={this.state.recipient.repeat_newPassword} name="repeat_newPassword" placeholder="Confirm Password" toggleMask
                                      onChange={this.changeRecipientHandler}
                                      className={errors?.recipient?.repeat_newPassword !== undefined ? "form-element p-invalid" : "form-element"}
                            />
                            {errors?.recipient?.repeat_newPassword !== undefined ?
                                <small className="offline">{errors?.recipient?.repeat_newPassword}</small>
                                : ""
                            }
                    </div>
                </div>
            </Dialog>
        );

        const communicationRecipientDialog = (
            <Dialog header={this.state.recipientActionType === 'new' ? "New Technician" : "Communication"}
                    visible={this.state.communicationRecipientDialog} modal
                    style={{width: '560px'}} contentStyle={{height: 150}}
                    footer={dialogFooterCommunication}
                    onHide={this.hideModal('communicationRecipientDialog')}>
                <div className="grid p-fluid">
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6" >
                        <p className="small mt-7 textBold"> Email: </p>
                        <InputText value={this.state.recipient.email} type="text" readonly/>
                    </div>
                    <div className="col-12 xl:col-6 lg:col-6 sm:col-6" >
                        <p className="small mt-7 textBold">Password: </p>
                        <Password value={this.state.recipient.pwd} name="pwd" placeholder="Password" toggleMask
                                  onChange={this.changeRecipientHandler}
                                  className={errors?.recipient?.pwd !== undefined ? "form-element p-invalid" : "form-element"}
                        />
                        {errors?.recipient?.repeat_newPassword !== undefined ?
                            <small className="offline">{errors?.recipient?.repeat_newPassword}</small>
                            : ""
                        }
                    </div>
                </div>
            </Dialog>
        );

        return (
            <div className="pageWrapper">
                <Toast ref={(el) => this.toast = el}/>
                <div className="grid">
                    {this.state.chargedYet ? '' :
                        <div className="col-9 ta-right" style={{position: "absolute", marginTop: -60}}>
                            <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE"
                                             animationDuration=".5s"/>
                        </div>
                    }
                </div>
                <Card height="height-100">
                    <div className="grid">
                            <div className="col-12 m-t-20">
                                <Toolbar className="p-mb-4" left={this.leftToolbarTemplate} right={this.rightToolbarTemplate}></Toolbar>
                            </div>
                            <div className="col-12">
                            <DataTable ref={(el) => this.dt = el} value={this.state.contacts} selection={this.state.dataTableSelectValue} right={this.rightToolbarTemplate}
                                       onSelectionChange={(e) => this.setState({dataTableSelectValue: e.value})}
                                       reorderableColumns={true} paginator={true} rows={50}
                                       globalFilter={this.state.globalFilter} header={header} resizableColumns={true} dataKey="id">
                                <Column selectionMode="multiple" headerStyle={{width: 50}}
                                        className="ta-center"></Column>
                                <Column field="id" header="id" className="ta-right" sortable/>
                                <Column field="username" header="Username" className="ta-right" sortable/>
                                <Column field="email" header="Email" className="ta-right" sortable/>
                                <Column field="first_name" header="First Name" className="ta-right" sortable/>
                                <Column field="last_name" header="Last Name" className="ta-right" sortable/>
                                <Column field="rol" header="Role" className="ta-right" sortable/>
                                <Column field="team" header="Team" className="ta-right" sortable/>
                                <Column  header="Active" body={this.isActive}/>
                                <Column field="phone_number" header="Phone Number" className="ta-right" />
                            </DataTable>
                        </div>
                    </div>
                </Card>
                {confirmDialog}
                {addRecipientDialog}
                {updateRecipientDialog}
                {resetRecipientDialog}
                {communicationRecipientDialog}
            </div>
        )
    }
}

export default Contacts;
