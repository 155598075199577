import axios from 'axios';

export class WorxflowServices {
    getConfig = (params) => {
        let access_token = sessionStorage.getItem('access_token');
        return {
            params: params,
            headers: {
                'Authorization': `Bearer ${access_token}`,
            }
        };
    }

    getListTrackStore() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/trackstore/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getListMobileWorkorder(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/mobile/workorder/list/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    createTrackstore(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/trackstore/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getStoreParent() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/store_parent/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getStoreChild(idStoreParent) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/store_child/${idStoreParent}/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getListTypeEquipment(idStoreParent) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/equipment/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getListFrozen(idStoreParent) {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/frozen/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    saveStoreParent(paramsParent) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/store_parent/create/`,
            paramsParent,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    saveStoreChild(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/store_child/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    saveTypeFrozen(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/frozen/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    saveTypeEquipment(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/equipment/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getAssets() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/template/assets/list/`).then(resp => {
            return resp.data;
        });
    }

    updateProfile(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/profile/update/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    changePassword(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/changePassword/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    forgotPasswordService(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/forgotPassword/`,
            params).then(resp => {
            return resp.data;
        });
    }

    resetPasswordService(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/resetPassword/`,
            params).then(resp => {
            return resp.data;
        });
    }

    getTrackstore(user_id, track_id, token){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/trackstore/find/${user_id}/${track_id}/${token}/`).then(resp => {
            return resp.data;
        });
    }
    postTrackstore(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/trackstore/find/`, params).then(resp => {
            return resp.data;
        });
    }

    sendCheckTrackstore(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/worxflow/trackstore/check/`, params).then(resp => {
            return resp.data;
        });
    }

    callRecipientList(){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/alert/recipient/list/`, this.getConfig()).then(resp => {
            return resp;
        });
    }
    callRecipientListReasons(){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/reason/list/`, this.getConfig()).then(resp => {
            return resp;
        });
    }
    techniciansList(){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/tech/list/`, this.getConfig()).then(resp => {
            return resp;
        });
    }
    getTeamList() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/admin/team/list/`, this.getConfig()).then(resp => {
            return resp.data;
        });
    }
    getRolList() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/admin/rol/list/`,  this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    addRecipientList(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/alert/recipient/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }
    addRecipientListReasons(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/reason/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }
    addRecipientTechnician(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/admin/technician/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }
    updateRecipientList(id, params){
        return axios.put(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/alert/recipient/${id}/update/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }
    auditTimesheet(id){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/audit/log/${id}/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }
    updateRecipientTechnician(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/admin/technician/update/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }
    resetRecipientTechnician(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/admin/technician/reset/password/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    communicationRecipientTechnician(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/odoo/check/v2/communication/`,
            params).then(resp => {
            return resp;
        });
    }
    updateRecipientListReasons(id, params){
        return axios.put(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/reason/${id}/update/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    deleteRecipientList(id){
        return axios.delete(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/alert/recipient/${id}/delete/`, this.getConfig()).then(resp => {
            return resp;
        });
    }

    deleteRecipientListReasons(id, params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/reason/${id}/delete/`, params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    sendSMSRecipient(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/alert/recipient/send/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    getTimesheetMobile(params, currentLink){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/list/${currentLink}`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    addTimesheet(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/create/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    updateTimesheet(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/update/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    updateTimesheetDif(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/update/difference/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    deleteTimesheet(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/delete/`,
            params,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

    searchReportTimesheet(params){
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/report/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }



    getTechniciansList(){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/tech/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getTeamsList(){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/team/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getListProcessedWeeks(){
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/mobile/week/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }
    getListUbication(params) {
        return axios.post(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/odoo/workorder/track/gps/last/location/`,
            params,
            this.getConfig()).then(resp => {
            return resp.data;
        });

    }

    getCustomerList() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/odoo/customers/list/`,
            this.getConfig()).then(resp => {
            return resp.data;
        });
    }

    getReasonList() {
        return axios.get(`${process.env.REACT_APP_ENDPOINT_EXPRESS}/api/v1/timesheet/reason/list/`,
            this.getConfig()).then(resp => {
            return resp;
        });
    }

}
