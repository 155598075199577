import {minYearForCalendar} from '../../components/utils/constants';
import {numberOfYearsAfterNow} from '../../components/utils/constants';

export class DateService {

    constructor() {
        this.minYear = parseInt(`${minYearForCalendar}`,10);
        this.maxYear = new Date().getFullYear() + parseInt(`${numberOfYearsAfterNow}`,10);
    }

    /**
     * yymmdd to date
     */
    curpStringToDate( date ) {
        if( !date || date.length < 6 ) {
            return null;
        }

        let year = parseInt('19'+date.substring(0,2),10);
        let month = parseInt(date.substring(2,4),10)-1;
        let day = parseInt(date.substring(4,6),10);

        if( year < this.minYear ) {
            year = this.minYear;
        }
        if( year > this.maxYear ) {
            year = this.maxYear;
        }

        let fechaDate = new Date();
        fechaDate.setFullYear( year, month, day );
        fechaDate.setHours(0,0,0,0);

        return fechaDate;
    }

    /**
     * yyyy-mm-dd to date
     */
    isoStringToDate( date ) {
        if( !date || date.length < 10 ) {
            return null;
        }

        let year = parseInt(date.substring(0,4),10);
        let month = parseInt(date.substring(5,7),10)-1;
        let day = parseInt(date.substring(8,10),10);

        if( year < this.minYear ) {
            year = this.minYear;
        }
        if( year > this.maxYear ) {
            year = this.maxYear;
        }

        let fechaDate = new Date();
        fechaDate.setFullYear( year, month, day );
        fechaDate.setHours(0,0,0,0);

        return fechaDate;
    }

    /**
     * dd/mm/yyyy to date
     */
    stringToDate( date ) {
        if( !date || date.length < 10 || date.indexOf( "_" ) !== -1 ) {
            return null;
        }

        let day = parseInt(date.substring(0,2),10);
        let month = parseInt(date.substring(3,5),10)-1;
        let year = parseInt(date.substring(6,10),10);

        if( year < this.minYear ) {
            year = this.minYear;
        }
        if( year > this.maxYear ) {
            year = this.maxYear;
        }

        let fechaDate = new Date();
        fechaDate.setFullYear( year, month, day );
        fechaDate.setHours(0,0,0,0);

        return fechaDate;
    }

    /**
     *  date to ddmmyyyy
     */
    dateToString( date ) {
        if( !date || !(date instanceof Date) || isNaN(date.getTime()) ) {
            return null;
        }

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        } 
        if (month < 10) {
            month = '0' + month;
        } 

        return day +''+ month +''+ year;
    }

    /**
     *  date to dd/mm/yyyy
     */
    dateToStringFront( date ) {
        if( !date || !(date instanceof Date) || isNaN(date.getTime()) ) {
            return null;
        }

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        } 
        if (month < 10) {
            month = '0' + month;
        } 

        return day +'/'+ month +'/'+ year;
    }    

    /**
     *  date to dd-mm-yyyy
     */
    dateToStringFront2( date ) {
        if( !date || !(date instanceof Date) || isNaN(date.getTime()) ) {
            return null;
        }

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        } 
        if (month < 10) {
            month = '0' + month;
        } 

        return day +'-'+ month +'-'+ year;
    }

    /**
     *  date to yyyy-mm-dd
     */
    dateToStringFront3( date ) {
        if( !date || !(date instanceof Date) || isNaN(date.getTime()) ) {
            return null;
        }

        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = '0' + month;
        }

        return year + '-' + month + '-' + day;
    }


    /**
     *  hh:mm to date
     */
    stringToTime( date ) {
        if( !date || date.length < 4 || date.indexOf( "_" ) !== -1 ) {
            return null;
        }

        let index = date.indexOf( ':' );

        let hours = parseInt(date.substring(0,index),10);
        let minutes = parseInt(date.substring(index+1,date.length),10);

        let fechaDate = new Date();
        fechaDate.setHours(hours,minutes,0,0);

        return fechaDate;
    }

    /**
     *  date to hhmm
     */    
    timeToString( date ) {
        if( !date || !(date instanceof Date) || isNaN(date.getTime()) ) {
            return null;
        }

        let hours = date.getHours();
        let minutes = date.getMinutes();
        if (hours < 10) {
            hours = '0' + hours;
        } 
        if (minutes < 10) {
            minutes = '0' + minutes;
        } 

        return hours +''+ minutes;
    }

    /**
     *  date to YYYY-MM-DD
     */
    dateConvertToISO( date ) {
        if( !date || !(date instanceof Date) || isNaN(date.getTime()) ) {
            return null;
        }

        return date.toISOString().slice(0,10);
    }

    /**
     *  time to HH:MM
     */
    toLocaleTimeString( time ) {
        if( !time || !(time instanceof Date) || isNaN(time.getTime()) ) {
            return null;
        }
        
        return time.toLocaleTimeString('es-MX', { hour12: false, hour: "2-digit", minute: "2-digit"});
    }

    /**
     *  compare range of dates, dateA <= dateB
     */
    compareRangeOfDates( dateA, dateB ) {
        if( !dateA || !(dateA instanceof Date) || isNaN(dateA.getTime()) ) {
            return true;
        }
        if( !dateB || !(dateB instanceof Date) || isNaN(dateB.getTime()) ) {
            return true;
        }
        return dateA.getTime() <= dateB.getTime();
    }
}
